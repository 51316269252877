(function() {
    'use strict';

    angular.module('loginApp')
        .run(loginAppRoutesToStyles);

    loginAppRoutesToStyles.$inject = ['PageService', 'routesConstant'];

    function loginAppRoutesToStyles(PageService, routesConstant) {
        PageService.addAflPageViewToStateMap(routesConstant.LOGIN.MAIN.stateName,
            PageService.AFLPAGEVIEW.LOGIN);
    }
})();
